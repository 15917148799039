import '../css/main.scss';




$(document).ready(function() {

    // -----------------------------------------------
    // Filter functions
    $('#filter').change(function(){

        var filter = $('#filter');
        $.ajax({
            url:filter.attr('action'),
            data:filter.serialize(), // form data
            type:filter.attr('method'), // POST
            beforeSend:function(xhr){
                filter.find('button').text('Processing...'); // changing the button label
            },
            success:function(data){
                filter.find('button').text('Apply filter'); // changing the button label back
                $('.list').html(data); // insert data
                
            }
        });

        return false; 
        
    });
        

    // -----------------------------------------------
    // functionality to copy text from inviteCode to clipboard
    // trigger copy event on click
    $(document).on('click', '.code__button', function(event) {
        event.preventDefault();
        console.log(event);
        copyToClipboard(event);
    });


    // event handler
    function copyToClipboard(e) {

    // find target element
    var
        t = e.target, 
        c = t.dataset.copytarget,
        inp = (c ? document.querySelector(c) : null);

    // check if input element exist and if it's selectable
    if (inp && inp.select) {
        // select text
        inp.select();
        try {
        // copy text
        document.execCommand('copy');
        inp.blur();

        // copied animation
        t.parentNode.classList.add('copied');
        console.log(t.classList);

        setTimeout(function() {
            t.parentNode.classList.remove('copied');
        }, 6000);
        } catch (err) {
        //fallback in case exexCommand doesnt work
        alert('please press Ctrl/Cmd+C to copy');
        }

    }

    }
});